<template>
  <b-card :style="`min-height: ${screenHeight}`">
    <p>A continuación te dejamos un video tutorial para que puedas conocer un poco más de Marketcenter y sus beneficios. </p>
    <div>
    <b-embed
    type="iframe"
    aspect="16by9"
    src="https://storage.googleapis.com/staging.ottawa-stage.appspot.com/video/ModeloFreemium.mp4"
    allowfullscreen
    ></b-embed>
</div>
  </b-card>
</template>
<script>
import { BEmbed } from 'bootstrap-vue'
export default {
  components: { BEmbed },
  data() {
    return {
      screenHeight: `${screen.height - 270}px`
    }
  }
}
</script>